import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite.css";


const countryNameToCode: { [key: string]: string } = {
    India: "in",
    Bangladesh: "bd",
    Argentina: "ar",
    Brazil: "br",
    Pakistan: "pk",
    Cambodia: "kh",
    "Bolivia, Plurinational State of": "bo",
    "Costa Rica": "cr",
    Chile: "cl",
    "South Africa": "za",
    Guatemala: "gt",
    Nigeria: "ng",
    Zambia: "zm",
    "El Salvador": "sv",
    Myanmar: "mm",
    Egypt: "eg",
    Uganda: "ug",
    Colombia: "co",
    Uruguay: "uy",
    Panama: "pa",
    Peru: "pe",
    Uzbekistan: "uz",
    Mexico: "mx",
    "United States of America": "us",
    Ghana: "gh",
    Kenya: "ke",
    Nepal: "np",
    Ecuador: "ec",
    Paraguay: "py",
    "Tanzania, United Republic of": "tz",
    Tunisia: "tn",
  };

const CountryListDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [countryCounts, setCountryCounts] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const currentDate = new Date();
  const defaultStartDate = new Date("2024-01-01T00:00:00.000Z");
  const [dateRange, setDateRange] = useState<[Date, Date]>([defaultStartDate, currentDate]);

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
      };

      setLoading(true);

      const response = await axios.get(
        `${config.REST_ENDPOINT}/api/users/country-list/dashboard?key=${config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const { countryCounts, totalCount } = response.data;

      setCountryCounts(countryCounts || []);
      setTotalCount(totalCount || 0);

    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch country data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  const getCountryFlag = (countryName: string) => {
    const countryCode = countryNameToCode[countryName];
    if (!countryCode) return null; 
    return `https://flagcdn.com/w80/${countryCode}.png`;
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Country List Dashboard</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          style={{
            textAlign: "center",
            marginBottom: 20,
            backgroundColor: "#2196F3",
            color: "white",
          }}
        >
          Total Users: {totalCount.toLocaleString()}
        </Typography>

        {countryCounts.length === 0 ? (
          <Typography variant="h6" align="center">
            No country data available
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {countryCounts.map((country, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                    padding: "16px",
                    backgroundColor: "#fff",
                  }}
                >
                  <img
                    src={getCountryFlag(country.country || "Unknown") || ""}
                    alt={`${country.country} flag`}
                    style={{
                      width: "50px",
                      height: "30px",
                      borderRadius: "4px",
                      marginBottom: "8px",
                      objectFit: "cover",
                      border: "1px solid #ddd",
                    }}
                    onError={(e) =>
                      ((e.target as HTMLImageElement).src = "/path-to-placeholder-image.png")
                    }
                  />
                  <Typography
                    fontWeight="bold"
                    textAlign="center"
                    marginBottom="8px"
                  >
                    {country.country || "Unknown"}
                  </Typography>
                  <Typography>{country.count.toLocaleString()}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
};

export default CountryListDashboard;
