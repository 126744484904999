import React, { useState } from "react";
import { useForm, Controller, FieldValues } from "react-hook-form";
import {
  Box,
  Card,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { callRestApi } from "../../utils/callRestApi";
import { Dayjs } from "dayjs";
import { toast } from "react-toastify";

interface FormData extends FieldValues {
  isForAll: "all" | "specific";
  userId: string;
  couponTitle: string;
  description: string;
  minAmount: string;
  maxAmount: string;
  cashbackAmount: string;
  bonusType: "rupees" | "percentage";
  wallet: "main" | "win" | "bonus";
  usageLimits: string;
  expireAt: Dayjs;
  isOnlyForFirstDeposit: "firstDeposit" | "lifetime";
}

const CreateCoupon: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      isForAll: "all",
      userId: "ALL_USERS",
      couponTitle: `<align="left">Get 100% Instant Cashback on Your Deposit!</align>`,
      description: `
<align="justified">
Deposit today and enjoy a 100% instant cashback credited to your bonus wallet! Boost your gameplay and maximize your wins!

<b>Terms:</b>

a. Minimum deposit of ₹100 required.
b. Cashback credited instantly to the bonus wallet.
c. Valid for new and existing users.

Deposit Now and Claim Your Cashback!
</align>
 `,
      bonusType: "rupees",
      wallet: "main",
      isOnlyForFirstDeposit: "lifetime",
    },
  });

  const isForAll = watch("isForAll");
  const bonusType = watch("bonusType");

  const validateForm = (data: FormData) => {
    let isValid = true;

    if (parseInt(data.usageLimits) <= 0) {
      setError("usageLimits", {
        type: "manual",
        message: "Usage limit must be greater than 0",
      });
      isValid = false;
    } else {
      clearErrors("usageLimits");
    }

    if (bonusType === "rupees") {
      if (data.minAmount !== data.cashbackAmount) {
        setError("cashbackAmount", {
          type: "manual",
          message:
            "For Rupees, Cashback Amount must be the same as Minimum Deposit Amount",
        });
        isValid = false;
      } else {
        clearErrors("cashbackAmount");
      }
    } else {
      clearErrors("cashbackAmount");
    }

    return isValid;
  };

  const onSubmit = async (data: FormData) => {
    if (!validateForm(data)) {
      return;
    }

    const payload: Record<string, any> = {
      description: data.description,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      expireAt: data.expireAt.toISOString(),
      bonusAmount: data.cashbackAmount,
      bonusType: data.bonusType,
      wallet: data.wallet,
      isDeleted: false,
      name: data.couponTitle,
      limit: parseInt(data.usageLimits),
      isOnlyForFirstDeposit: data.isOnlyForFirstDeposit === "firstDeposit",
      status: "active",
      isVisible: true,
      partner: "empire",
      isForAll: data.isForAll === "all",
      userId: data.isForAll === "all" ? "ALL_USERS" : data.userId,
    };

    try {
      const response = await callRestApi("/promo/coupons", "POST", payload);

      if (response && response.data) {
        toast.success("Coupon created successfully!");
        console.log("Coupon created successfully:", response.data);
      } else {
        toast.warn("Response is undefined or does not contain data.");
        console.warn("Response is undefined or does not contain data.");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data?.message || error.message;

        if (errorMessage.includes("Promo code already exists")) {
          toast.error(
            "Promo code already exists. Please use a different code."
          );
        } else {
          toast.error(`Error creating coupon: ${errorMessage}`);
        }
      } else {
        toast.error(`Error creating coupon: ${error.message}`);
      }

      console.error("Error creating coupon:", error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Card sx={{ p: 3 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <h2>Create Coupons</h2>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="isForAll"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    onChange={(event) => {
                      field.onChange(event);
                      if (event.target.value === "all") {
                        setValue("userId", "ALL_USERS");
                      } else {
                        setValue("userId", "");
                      }
                    }}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="specific"
                      control={<Radio />}
                      label=" User"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            {isForAll === "specific" && (
              <Grid item xs={12}>
                <Controller
                  name="userId"
                  control={control}
                  rules={{ required: "User ID is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Enter User ID"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Controller
                name="couponTitle"
                control={control}
                rules={{ required: "Coupon Title is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Coupon Title"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            {/* Minimum Amount */}
            <Grid item xs={12} md={6}>
              <Controller
                name="minAmount"
                control={control}
                rules={{ required: "Minimum Deposit Amount is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Minimum Deposit Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Coupon Description"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            {/* Maximum Amount */}
            <Grid item xs={12} md={6}>
              <Controller
                name="maxAmount"
                control={control}
                rules={{
                  required: "Maximum Amount is required",
                  min: { value: 1, message: "Amount must be greater than 0" },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Maximum Deposit Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            {/* Cashback Amount */}
            <Grid item xs={12} md={6}>
              <Controller
                name="cashbackAmount"
                control={control}
                rules={{ required: "Cashback Amount is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Cashback Amount"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Select Coupon Type</InputLabel>
              <Controller
                name="bonusType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="rupees"
                      control={<Radio />}
                      label="Rupees"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Percentage"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Select Cashback Credit Wallet</InputLabel>
              <Controller
                name="wallet"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="main"
                      control={<Radio />}
                      label="Main Wallet"
                    />
                    <FormControlLabel
                      value="win"
                      control={<Radio />}
                      label="Win Wallet"
                    />
                    <FormControlLabel
                      value="bonus"
                      control={<Radio />}
                      label="Bonus Wallet"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="usageLimits"
                control={control}
                rules={{
                  required: "Usage Limits is required",
                  min: {
                    value: 1,
                    message: "Usage limit must be greater than 0",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Enter Usage Limits"
                    fullWidth
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Controller
                  name="expireAt"
                  control={control}
                  rules={{ required: "Expiry Date and Time is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      {...field}
                      label="Select Expired Date and Time"
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Applicability</InputLabel>
              <Controller
                name="isOnlyForFirstDeposit"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="firstDeposit"
                      control={<Radio />}
                      label="First Deposit"
                    />
                    <FormControlLabel
                      value="lifetime"
                      control={<Radio />}
                      label="Any Deposit"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" type="submit">
                  CREATE
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {}}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </LocalizationProvider>
  );
};

export default CreateCoupon;
