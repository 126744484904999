import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../../../config";
import { getItemWithExpiry } from "../../../utils";
import { toast } from "react-toastify";
import StatsCard from "../../../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [gameDashboardStats, setGameDashboardStats] = useState<any>({});
  
  const date = new Date();
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType: "all", // Assuming all games; modify as needed
      };

      setLoading(true);

      const response = await axios.get(
        `${config.REST_ENDPOINT}/api/callbreak-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGameDashboardStats(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>
        Callbreak Game Dashboard
      </h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Grid
          container
          justifyContent="left"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total CallBreak Games Count
                </Typography>
              }
              value={gameDashboardStats["Total CB Games count"] ?? 0}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[1 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total CB JoinFee Amount
                </Typography>
              }
              value={
                "₹" +
                (
                  gameDashboardStats["Total CB minJoinAmount count"] ?? 0
                ).toFixed(2)
              }
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[0 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total CallBreak Commission
                </Typography>
              }
              value={
                "₹" +
                (gameDashboardStats["Total CallBreak Commission"] ?? 0).toFixed(
                  2
                )
              }
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[2 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={<Typography fontWeight="bold">Total Won/Lost</Typography>}
              value={`${
                gameDashboardStats.outcomeCounts?.won ?? 0
              }/${gameDashboardStats.outcomeCounts?.lost ?? 0}`}
              icon={
                <SportsEsportsIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          style={{
            marginTop: -20,
            textAlign: "center",
            marginBottom: 20,
            backgroundColor: "#2196F3",
            color: "white",
          }}
        >
               Game Join Amount And Tables
        </Typography>

        {Object.keys(gameDashboardStats.joinAmountAndTable ?? {}).length ===
        0 ? (
          <Typography variant="h6" align="center">
            There is no joining fee and tables are available.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {Object.entries(gameDashboardStats.joinAmountAndTable).map(
              ([key, value], index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <StatsCard
                    title={
                      <Typography fontWeight="bold">
                        Join Amount {`₹${key}`}
                      </Typography>
                    }
                    value={value}
                    subheader={
                      <Typography fontWeight="bold">
                        Tables: {value as number}
                      </Typography>
                    }
                    icon={
                      <SportsEsportsIcon
                        style={{
                          fontSize: "20px",
                          color: colorPalette[index % colorPalette.length],
                        }}
                      />
                    }
                  />
                </Grid>
              )
            )}
          </Grid>
        )}
      </div>
      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;